import React, { useState, useEffect } from "react"
import Layout from "../retech/components/layout/Layout"
import {
  InputGroup,
  FormControl,
  Accordion,
  Card,
  Button,
} from "react-bootstrap"
import { Helmet } from "react-helmet"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Link, graphql } from "gatsby"
export default function FaqPage({ data }) {
  const Productname = data.Productname.nodes
  const url = typeof window !== "undefined" ? window.location.href : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  const [CategorySelected, setCategorySelected] = useState("")
  const [CategorySearch, setCategorySearch] = useState()
  const [searchTerm, setSearchTerm] = React.useState("")
  const [searchResults, setSearchResults] = React.useState([])

  const general = data.general.nodes

  const handleChange = event => {
    setSearchTerm(event.target.value)
  }
  React.useEffect(() => {
    const results = Productname.filter(Productname =>
      Productname.Title.toLowerCase().includes(searchTerm)
    )
    var search = searchTerm?.toLowerCase() ?? "";

    let general1 = general.filter(x =>
      x?.GeneralFAQ?.length > 0 &&
      x?.GeneralFAQ[0]?.Title?.toLowerCase().includes(search)
    )
    if (search === "") general1 = general;
    setSearchResults([results, general1])

  }, [searchTerm])

  /*   useEffect(() => {
      if (Productname?.length > 0) setCategorySelected(Productname[0].Title)
      if (general?.GeneralFAQ?.length > 0) setCategorySelected(general[0].GeneralFAQ.Title)
      Productname.forEach(x => {
        x.search = x.Title
        x.FAQs?.forEach(y => {
          x.search += "|" + y.Question + "|" + y.Answer
        })
      })
      general.forEach(x => {
  
        x.GeneralFAQ?.forEach(y => {
          x.search += "|" + y.Title + "|" + y.description
        })
      })
    }, [Productname, general]) */
  const [idx, setIdx] = useState([])
  return (
    <Layout>
      <Helmet>
        <title>Retech | FAQs</title>

        <meta
          name="description"
          content={data.metadata.nodes[0].FAQMetaDescription}
        />
        <meta name="keywords" content={data.metadata.nodes[0].FAQkeywords} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta
          property="og:title"
          content={data.metadata.nodes[0].FAQMetaTitle}
        />
        <meta
          property="og:description"
          content={data.metadata.nodes[0].FAQMetaTitle}
        />
        <meta property="og:image" content={url + "logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta
          property="twitter:title"
          content={data.metadata.nodes[0].FAQMetaTitle}
        />
        <meta
          property="twitter:description"
          content={data.metadata.nodes[0].FAQMetaDescription}
        />
        <meta property="twitter:image" content={url + "logo.svg"} />
      </Helmet>
      <div className="container faq">
        <div className="col-md-12 my-5 py-5 border-bottom">
          <div className="row">
            <div className="col-md-7 mb-5">
              <h1 className="font-weight-bolder">Retech FAQs</h1>
              <p className="text-muted">
                Search all frequently asked questions
              </p>
            </div>
            <div className="col-md-5 align-self-center search-loup mb-5">
              <InputGroup.Append className="shadow-none has-search">
                <FormControl
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="Search"
                  className="shadow-none bg-light border-0"
                  value={searchTerm}
                  onChange={handleChange}
                />
                <span className=" form-control-feedback">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </InputGroup.Append>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <h6 className="font-weight-bold">Categories</h6>

              {Productname.map((node, index) => {
                return (
                  <>
                    <small>
                      <Link
                        className="text-dark"
                        key={index}
                        to={"#" + node.Title}
                      >
                        {node.Title}
                      </Link>
                    </small>
                    <br></br>
                  </>
                )
              })}
              <small>
                <Link
                  className="text-dark"

                  to="#general"
                >
                  General
                </Link>
              </small>
            </div>
            <div className="col-md-8">
              {searchResults[0]?.map((node, index) => {
                return (
                  <>
                    <h4
                      className="font-weight-bolder mb-3 test"
                      id={node.Title}
                    >
                      {node.Title}
                    </h4>
                    {node?.FAQs?.length > 0 &&
                      node.FAQs.map((node2, index2) => (
                        <Accordion
                          defaultActiveKey={index + "0"}
                          className="border-top py-3"
                        >
                          <Accordion.Toggle
                            eventKey={index + ""}
                            as={Button}
                            variant="link"
                            className="shadow-none w-100 text-left"
                            onClick={() => {
                              if (idx.includes(index))
                                setIdx(idx.filter(i => i !== index))
                              else setIdx([...idx, index])
                            }}
                          >
                            {node2.Question}{" "}
                            {idx.includes(index) ? (
                              <img
                                src="/img/ArrowRight.svg"
                                alt="arrow right"
                                className="float-right"
                              />
                            ) : (
                              <img
                                src="/img/ArrowRight.svg"
                                alt="arrow right"
                                className="float-right down"
                              />
                            )}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={index + ""}>
                            <Card.Body>
                              <p className="product-par">{node2.Answer}</p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Accordion>
                      ))}
                  </>
                )
              })}
              <h4
                className="font-weight-bolder mb-3 test"
                id={'general'}
              >
                General
              </h4>
              {searchResults[1]?.map((node, index) => {
                return (
                  <>

                    {node?.GeneralFAQ?.length > 0 &&
                      node.GeneralFAQ.map((node2, index2) => (
                        <Accordion
                          defaultActiveKey={index + "0"}
                          className="border-top py-3"
                        >
                          <Accordion.Toggle
                            eventKey={index + ""}
                            as={Button}
                            variant="link"
                            className="shadow-none w-100 text-left"
                            onClick={() => {
                              if (idx.includes(index))
                                setIdx(idx.filter(i => i !== index))
                              else setIdx([...idx, index])
                            }}
                          >
                            {node2.Title}{" "}
                            {idx.includes(index) ? (
                              <img
                                src="/img/ArrowRight.svg"
                                alt="arrow right"
                                className="float-right"
                              />
                            ) : (
                              <img
                                src="/img/ArrowRight.svg"
                                alt="arrow right"
                                className="float-right down"
                              />
                            )}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={index + ""}>
                            <Card.Body>
                              <p className="product-par">{node2.description} </p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Accordion>
                      ))}
                  </>
                )
              })}

              {Productname.filter(producttrue =>
                producttrue.Title.toLowerCase().includes(searchTerm)
              ).length === 0 && <p>No results found, please try a different search query or return <a href='/'>Home</a></p>}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    Productname: allProductsJson(sort: {fields: OrderId, order: ASC}, filter: {Title: {ne: null}}) {
      nodes {
        OrderId
        Title
        CTALink
        FAQs {
          Answer
          Question
        }
      }
    }
    general: allHomeJson {
      nodes {
        GeneralFAQ {
          Title
          description
        }
      }
    }
    metadata: allMetadataJson(filter: { FAQMetaTitle: { ne: null } }) {
      nodes {
        FAQMetaDescription
        FAQMetaTitle
        FAQkeywords
      }
    }
  }
`
